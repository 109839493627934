// @flow
import {
  PL_DEFAULT_LOAN_TERM,
  PL_MINIMUM_AMOUNT,
  SOFT_QUOTE_NZ_URL,
  SOFT_QUOTE_RETRIEVE_URL,
  SOFT_QUOTE_LOANS_NZ_URL,
  LOAN_PURPOSE
} from './constants';
import { getUrlFromEnv } from './getUrlFromEnvUtil';

export const SOFT_QUOTE_BASE_URL = getUrlFromEnv('funnel-start-soft-quote');

export const SOFT_QUOTE_LOANS_BASE_URL = getUrlFromEnv(
  'funnel-start-loans-soft-quote'
);

export const SOFT_QUOTE_BROKER_BASE_URL = getUrlFromEnv(
  'funnel-start-broker-soft-quote'
);

export const SOFT_QUOTE_URL = `${SOFT_QUOTE_BASE_URL}${SOFT_QUOTE_NZ_URL}`;

export const RETRIEVE_URL = `${SOFT_QUOTE_RETRIEVE_URL}`;

export const SOFT_QUOTE_LOANS_URL = `${SOFT_QUOTE_LOANS_BASE_URL}${SOFT_QUOTE_LOANS_NZ_URL}`;

export const RETRIEVE_LOANS_URL = `${SOFT_QUOTE_LOANS_BASE_URL}`;

export const SOFT_QUOTE_BROKER_URL = `${SOFT_QUOTE_BROKER_BASE_URL}`;

export const redirectUrl = (
  purpose: string = LOAN_PURPOSE.SELECT_PURPOSE
): string => {
  const purposeOtherText = purpose === LOAN_PURPOSE.OTHER ? `&other=` : '';
  return (
    `${SOFT_QUOTE_URL}?amount=${PL_MINIMUM_AMOUNT}&purpose=${purpose}` +
    `&period=${PL_DEFAULT_LOAN_TERM}${purposeOtherText}`
  );
};

export const redirectLoansUrl = (
  purpose: string = LOAN_PURPOSE.SELECT_PURPOSE
): string => {
  const purposeOtherText = purpose === LOAN_PURPOSE.OTHER ? `&other=` : '';
  return (
    `${SOFT_QUOTE_LOANS_URL}?amount=${PL_MINIMUM_AMOUNT}&purpose=${purpose}` +
    `&period=${PL_DEFAULT_LOAN_TERM}${purposeOtherText}`
  );
};
