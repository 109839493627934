import {
  PL_BROKER_MINIMUM_AMOUNT,
  PL_MINIMUM_AMOUNT,
} from '../../utils/constants';


/* resetToDefault
 * Reset session storage values used in soft quote widget
 *
 */
export function resetToDefault (isBroker = false, purpose = 'purpose') {
  if (typeof window !== 'undefined') {
    sessionStorage.setItem(
      'loanAmount',
      isBroker ? PL_BROKER_MINIMUM_AMOUNT : PL_MINIMUM_AMOUNT
    );
    sessionStorage.setItem('purpose', purpose);
    sessionStorage.setItem('repaymentPeriod', '5');
    sessionStorage.setItem('purposeOther', '');
  }
}
